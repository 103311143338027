body {
	background-color: #ffab40;
}

.bg-footer {
	background-color: #010000 !important;
}
.bg-copyright {
	/*background-color: #ffab40 !important;*/

	background-color: #010000 !important;
}

.bg-emphasis {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url(./image/sign_up.jpg);
	background-size: cover;
	min-height: 40vh;
}

.bg-user {
	background-color: lemonchiffon;
	background-size: cover;
	min-height: 40vh;
}

.box-user {
	padding-top: 10vh;
}

.bg-dark {
	background-color: #000000 !important;
}

.box {
	min-height: 50vh;
}

.logo-name {
	font-family: 'Monoton', cursive;
	font-size: 28px;
	letter-spacing: 1rem;
}

.slogan-1 {
	font-family: 'Major Mono Display', monospace;
	color: #ffab40 important!;
}

.slogan-2 {
	font-family: 'Spartan', sans-serif;
}
p.slogan-1 {
	color: #ffab40 important!;
}

.slogan-anim {
	position: relative;
	animation-name: slogan_animation;
	animation-duration: 2s;
	animation-timing-function: ease-out;
}
.Login-component {
	/* The image used */
	background-image: url(../src/image/register_start.jpg);

	/* Full height */

	height: 100vh;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@keyframes slogan_animation {
	0% {
		left: -800px;
		top: 0px;
	}

	85% {
		left: 50px;
		top: 0px;
	}

	100% {
		left: 0px;
		top: 0px;
	}
}
.display-4 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1.2;
}

hr.new4 {
	border: 1px solid white;
}
hr.descript {
	border: 1px solid white;
	width: 70vw;
}
hr.descript-user {
	border: 1px solid black;
	width: 70vw;
}
img.mechanic {
	border-radius: 5%;
}
img.store {
	border-radius: 5%;
}
img.fullstackcycling {
	border-radius: 5%;
}
img.tools {
	border-radius: 5%;
}
hr.descript-history {
	width: 63vw;
}
hr.hr-before {
	border: 1px solid white;
	width: 40vw;
}
img.tes-pic {
	border-radius: 50%;
}

.card-history {
	/* Add shadows to create the "card" effect */
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	border-radius: 5px;
	padding: 2px 16px; /* 5px rounded corners */
}

/* On mouse-over, add a deeper shadow */
.card-history:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container-history {
	padding: 8px 16px;
}

.youtube-vid {
	width: 40vw;
	height: 50vh;
}

.img-size {
	width: 45vw;
	height: 42vh;
}

/* Extra small devices (phones, 600px and down) 
default - xs*/
@media only screen and (max-width: 575px) {
	.logo-name {
		font-family: 'Monoton', cursive;
		font-size: 19px;
		letter-spacing: 1rem;
	}
	hr.descript-history {
		width: 66vw;
	}
	.img-size {
		width: 92vw;
		height: 40vh;
	}
	.youtube-vid {
		width: 90vw;
		height: 50vh;
		margin-left: 2vw;
		margin-right: 2vw;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
	.bg-emphasis {
		min-height: 50vh;
	}
	.h1,
	h1 {
		font-size: 2.5rem;
	}
	hr.descript-history {
		width: 54vw;
	}
	.youtube-vid {
		width: 80vw;
		height: 50vh;
		margin-left: 2vw;
		margin-right: 2vw;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.bg-emphasis {
		min-height: 55vh;
	}
	.h1,
	h1 {
		font-size: 3rem;
	}
	hr.descript-history {
		width: 68vw;
	}
	.img-size {
		width: 88vw;
		height: 50vh;
	}
	.youtube-vid {
		width: 40vw;
		height: 50vh;
		margin-left: 2vw;
		margin-right: 2vw;
	}
}
@media screen and (min-width: 577px) and (max-width: 767px) {
	.img-size {
		width: 68vw;
		height: 50vh;
		margin-left: 2vw;
		margin-right: 2vw;
	}
}

@media screen and (min-width: 769px) and (max-width: 991px) {
	.img-size {
		width: 68vw;
		height: 50vh;
		margin-left: 5vw;
		margin-right: 5vw;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.bg-emphasis {
		min-height: 65vh;
	}
	.h1,
	h1 {
		font-size: 3.5rem;
	}
	hr.descript-history {
		width: 63vw;
	}
	.img-size {
		width: 42vw;
		height: 40vh;
		margin-left: 3vw;
		margin-right: 3vw;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.bg-emphasis {
		min-height: 75vh;
	}
	.slogan-1 h1 {
		font-size: 0px !important;
	}
	.img-size {
		width: 35vw;
		height: 40vh;
		margin-left: 3vw;
		margin-right: 3vw;
	}
}
